import { template as template_44a9e8b12e63413c991772c3effa3653 } from "@ember/template-compiler";
const WelcomeHeader = template_44a9e8b12e63413c991772c3effa3653(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
