import { template as template_1a79f6a402c8415db974d0fee4fbed36 } from "@ember/template-compiler";
const FKLabel = template_1a79f6a402c8415db974d0fee4fbed36(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
