import { template as template_12a6053913b74ee18b037078d06fc0f8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_12a6053913b74ee18b037078d06fc0f8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
